<template>
  <v-expansion-panels v-model="panel" multiple flat>
    <v-row>
      <v-col lg="6" cols="12" v-for="(item, i) in lessons" :key="'lesson' + i">
        <v-expansion-panel :disabled="item.is_locked && !isBought">
          <v-expansion-panel-header class="moholand-shadow rounded-lg">
            <template v-slot:actions>
              <v-icon size="50">
                mdi-chevron-down
              </v-icon>
            </template>
            <div class="d-flex justify-space-between">
              <v-row no-gutters>
                <v-col cols="2">
                  <v-img
                    :src="require('../../assets/icons/autumn.png')"
                    class="grey-icon icon-medium"
                  />
                </v-col>
                <v-col cols="10" class="text-right">
                  <h2 class="mt-2">
                    {{ item.title }}
                  </h2>
                  <div
                    class="grey--text mt-2 fix-html-images lesson-desc"
                    style="line-height: 2.4em"
                    v-html="item.desc"
                  ></div>
                </v-col>
              </v-row>
              <v-icon class="ml-2" v-if="item.is_locked && !isBought">
                mdi-lock
              </v-icon>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="grey lighten-2 mx-2 rounded-b-lg"
            v-if="item.is_locked ? $store.state.userData && isBought : true"
          >
            <div
              class="d-flex flex-wrap align-center"
              v-for="(gallery, i) in item.gallery"
              :key="'gallery' + i"
            >
              <div dir="rtl">{{ "ویدئو " + (i + 1) }}</div>
              <v-spacer />
              <v-btn
                color="success"
                class="px-8 ma-4 rounded-lg"
                @click="$emit('playVideo', gallery.url || gallery, item.title)"
              >
                تماشای آنلاین
              </v-btn>
              <v-btn
                color="info"
                class="px-8 ma-4 rounded-lg"
                @click="download(gallery.url || gallery)"
              >
                دانلود ویدئو
              </v-btn>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-col>
    </v-row>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    data: {
      type: Array || Number,
      default: 5,
    },
    isBought: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panel: [],
    };
  },
  methods: {
    download(url) {
      window.open(url, "_blank");
    },
  },
  computed: {
    lessons: {
      get() {
        return this.data.sort((a, b) => a.order - b.order);
      },
    },
  },
};
</script>

<style scoped>
.v-expansion-panels {
  z-index: auto !important;
}
.lesson-desc >>> p {
  margin-bottom: 0;
}
</style>
