<template>
  <div
    class="d-flex elevation-4 rounded-lg white pa-0"
    style="overflow: hidden"
  >
    <v-btn
      elevation="0"
      fab
      class="rounded-r-lg rounded-0"
      color="white"
      style="width: 15%"
      @click="copyToClipboard"
    >
      <v-icon class="grey--text">mdi-link-variant</v-icon>
    </v-btn>
    <div class="url grey lighten-3 rounded-l-lg rounded-0" style="width: 85%">
      <h3 class="grey--text ltr ma-2" ref="urlDiv">
        {{ url }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyToClipboard() {
      let range,
        element = this.$refs.urlDiv;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped></style>
