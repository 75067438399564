<template>
  <v-container fluid>
    <v-row class="orange">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="2" align-self="center" class="text-center">
            <div class="similar-title text-center white--text">
              دوره های
              <br />
              مشابه
            </div>
            <v-btn large outlined color="white" class="mt-3" to="/courses">
              مشاهده همه
            </v-btn>
          </v-col>
          <v-col cols="12" md="10" v-if="loading">
            <my-swiper
              :data="Array(4)"
              :maxCount="4"
              hasNavigation
              dark
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default>
                <v-skeleton-loader type="card, actions" class="rounded-0" />
              </template>
            </my-swiper>
          </v-col>
          <v-col cols="12" md="10" v-else>
            <my-swiper
              :data="similar"
              :sizes="[3, 3, 2, 2, 1]"
              hasNavigation
              fixSpace
              dark
              :spaceBetween="10"
              :loop="false"
              class="amazing-offer-swiper"
            >
              <template #default="{props}">
                <course-item :item="props.item" />
              </template>
            </my-swiper>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import CourseItem from "../CourseItem.vue";
import MySwiper from "../MySwiper.vue";
export default {
  components: { MySwiper, CourseItem },
  data() {
    return {
      similar: [],
      loading: true,
    };
  },
  mounted() {
    this.GetData();
  },
  props: {
    slug: {
      type: String,
    },
  },
  methods: {
    GetData: function() {
      if (!this.loading) this.loading = true;
      MyAxios.get("/courses/similar/" + this.slug, {
        params: {
          with: "media",
        },
      })
        .then((response) => {
          this.similar = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  watch: {
    slug() {
      this.GetData();
    },
  },
};
</script>

<style></style>
